export const environment = {
  production: false,
  app: "sdcom",
  shade: "#36408f",
  org: "xDP48IppF3HinfWuhCVu",
  name: "SportsDietitan.com",
  bundle: "com.sophusnutrition.sdcom",
  website: "http://sportsdietitian.com",
  privacyPolicy: "https://www.sportsdietitian.com/privacy-policy",
  appStoreUrl: "https://apps.apple.com/us/app/sportsdietitian-com/id1474955385",
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.sophusnutrition.sdcom",
  facebook: "https://www.facebook.com/sportsdietitian.com.nutrition/",
  instagram: "https://www.instagram.com/sportsdietitian.com.nutrition/",
  welcomeVideoId: "XVPjnOrZDZU",
  firebase: {
    apiKey: "AIzaSyAM7lR84C5atIzIQ1ocnCQdnNh_dgk0Ov8",
    authDomain: "sophus-nutrition-dev.firebaseapp.com",
    databaseURL: "https://sophus-nutrition-dev.firebaseio.com",
    projectId: "sophus-nutrition-dev",
    storageBucket: "sophus-nutrition-dev.appspot.com",
    messagingSenderId: "959359868637",
    appId: "1:959359868637:web:231932b5a82212d2",
  },
  stripe: "pk_test_bVrwooTAyid9h7JS1kx1N5RE",
};
